@use "@ag-grid-community/styles" as ag;
@include ag.grid-styles(( theme: alpine ));
@include ag.grid-styles(( theme: alpine-dark ));

@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100vh;
  width: 100vw;
}

@import "~primeicons/primeicons.css";
@import "~primeng/resources/themes/lara-light-blue/theme.css";
@import "~primeng/resources/primeng.min.css";
@import "~@fortawesome/fontawesome-svg-core/styles.css";

ngx-intl-tel-input .iti {
  width: 100%;
}

body.font-small {
  :not(.font-static) {
    font-size: 0.875rem;

    fa-icon > svg {
      height: 1em;
    }

    nav > div > a > fa-icon > svg {
      height: 1.5em;
    }

    &[class$="text-xs"] { font-size: 0.625rem; }
    &[class$="text-sm"] { font-size: 0.75rem; }
    &[class$="text-base"] { font-size: 0.875rem; }
    &[class$="text-lg"] { font-size: 1rem; }
    &[class$="text-xl"] { font-size: 1.125rem; }
    &[class$="text-2xl"] { font-size: 1.25rem; }
    &[class$="text-3xl"] { font-size: 1.5rem; }
    &[class$="text-4xl"] { font-size: 1.875rem; }
    &[class$="text-5xl"] { font-size: 2.25rem; }
    &[class$="text-6xl"] { font-size: 3rem; }
    &[class$="text-7xl"] { font-size: 4rem; }
    &[class$="text-8xl"] { font-size: 6rem; }
    &[class$="text-9xl"] { font-size: 8rem; }


  }

  .font-static.text-base {
    color: rgb(143 196 35);
  }
}

body.font-normal {
  :not(.font-static) {
    font-size: 1rem;

    fa-icon > svg {
      height: 1em;
    }

    nav > div > a > fa-icon > svg {
      height: 1.85em;
    }

    &[class$="text-xs"] { font-size: 0.75rem; }
    &[class$="text-sm"] { font-size: 0.875rem; }
    &[class$="text-base"] { font-size: 1rem; }
    &[class$="text-lg"] { font-size: 1.125rem; }
    &[class$="text-xl"] { font-size: 1.25rem; }
    &[class$="text-2xl"] { font-size: 1.5rem; }
    &[class$="text-3xl"] { font-size: 1.875rem; }
    &[class$="text-4xl"] { font-size: 2.25rem; }
    &[class$="text-5xl"] { font-size: 3rem; }
    &[class$="text-6xl"] { font-size: 4rem; }
    &[class$="text-7xl"] { font-size: 5rem; }
    &[class$="text-8xl"] { font-size: 6rem; }
    &[class$="text-9xl"] { font-size: 8rem; }
  }

  .font-static.text-xl {
    color: rgb(143 196 35);
  }
}

body:not(.font-small, .font-large) .font-static.text-xl {
  color: rgb(143 196 35);
}

body.font-large {
  :not(.font-static) {
    font-size: 1.125rem;

    fa-icon > svg {
      height: 1em;
    }

    nav > div > a > fa-icon > svg {
      height: 2em;
    }

    &[class$="text-xs"] { font-size: 0.875rem; }
    &[class$="text-sm"] { font-size: 1rem; }
    &[class$="text-base"] { font-size: 1.125rem; }
    &[class$="text-lg"] { font-size: 1.25rem; }
    &[class$="text-xl"] { font-size: 1.5rem; }
    &[class$="text-2xl"] { font-size: 1.875rem; }
    &[class$="text-3xl"] { font-size: 2.25rem; }
    &[class$="text-4xl"] { font-size: 3rem; }
    &[class$="text-5xl"] { font-size: 4rem; }
    &[class$="text-6xl"] { font-size: 5rem; }
    &[class$="text-7xl"] { font-size: 6rem; }
    &[class$="text-8xl"] { font-size: 7rem; }
    &[class$="text-9xl"] { font-size: 8rem; }
  }

  .font-static.text-2xl {
    color: rgb(143 196 35);
  }
}

.p-datatable {
  border: 2px solid lightgray;
  border-radius: 1rem;
}
.p-datatable-wrapper {
  border-radius: 1rem 1rem 0 0;
}
.p-paginator {
  justify-content: end !important;
  border-radius: 0 0 1rem 1rem !important;
}

.p-float-label input:focus ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label{
  top: 0.15rem !important;
}

.p-float-label label {
  background-color: white;
  padding: 0 0.25rem;
}

.p-timeline {
  padding-bottom: 1rem;

  &.p-timeline-horizontal .p-timeline-event-connector {
    width: auto;
  }
}
.p-timeline-event {
  transform: translateX(1rem);
}
.p-timeline.p-timeline-horizontal .p-timeline-event-opposite {
  padding: 0px;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding-top: 0px;
  color: rgb(160 160 160);

  .current {
    color: rgb(143, 196, 35);
  }
}
.p-timeline-event {
  min-height: auto;
}

.p-timeline .p-timeline-event-marker {
  border: 2px solid rgb(255 255 255);
  background-color: rgb(143 196 35);
  box-shadow: 0 0 0 1px rgb(143 196 35);
  z-index: 5;

  &.current {
    outline: 1px solid rgb(143, 196, 35);
    outline-offset: 2px;
  }
  &.future {
    box-shadow: 0 0 0 1px rgb(184 184 184);
    border-color: rgb(255 255 255);
    background-color: rgb(184 184 184);
  }
}

.switch-page {
  position: absolute;
  transition: ease-in-out 0.3s;
  opacity: 0;

  &.active {
    opacity: 1;
  }
  &.past {
    transform: translateX(-100%);
    height: 0px;
  }
  &.future {
    transform: translateX(100%);
    height: 0px;
  }
}

.profile .switch-page {
  position: inherit;
}

@media (min-width: 640px) {
  .p-timeline {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.hide-scroll-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}
